@layer tailwind-base, primeng;

@import 'tailwindcss/base' layer(tailwind-base);
@tailwind components;
@tailwind utilities;

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import './flags.css';
@import './fonts.scss';
@import '~quill/dist/quill.snow.css';

h1 {
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-decoration: none;
}

h2 {
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration: none;
}

h3 {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration: none;
}

ion-input.input-fill-solid.sc-ion-input-md-h,
ion-textarea.textarea-fill-solid.sc-ion-textarea-md-h {
  --background: var(--ion-color-secondary);
  --border-radius: 0px;
}

ion-input .label-text.sc-ion-input-md,
.sc-ion-input-md-s > [slot='label'] {
  line-height: 19px;
}

gt-admin-edit-inscription .p-dropdown,
gt-admin-messages .p-dropdown,
gt-admin-edit-create-edition .p-dropdown,
gt-admin-edit-create-edition .p-calendar .p-inputtext,
gt-admin-edit-create-edition .p-inputnumber .p-inputtext {
  background: #f1efe7 !important;
  border-radius: 0px !important;
  border: none !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #f1efe7;
  color: black;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #f1efe7;
  border-color: #f1efe7;
  color: black;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: #ffd547 !important;
  color: #000 !important;
  font-weight: 700;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  font-weight: 400;
  color: #000;
}